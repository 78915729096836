import { BaseIconPathStyle, BaseSvgStyle } from '../IconBaseStyle';
import { ComponentProps } from '../SVGBase';

function House({ size, color }: ComponentProps) {
  return (
    <BaseSvgStyle
      size={size}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <BaseIconPathStyle
        d="M15.75 23.25V15.75C15.75 15.3522 15.5919 14.9706 15.3106 14.6893C15.0293 14.408 14.6478 14.25 14.25 14.25H9.74998C9.35216 14.25 8.97062 14.408 8.68932 14.6893C8.40802 14.9706 8.24998 15.3522 8.24998 15.75V23.25H1.72998C1.46476 23.25 1.21041 23.1446 1.02287 22.9571C0.835337 22.7696 0.72998 22.5152 0.72998 22.25V9.75C0.764576 9.45869 0.907339 9.19101 1.12998 9L12 0.75L16 3.75V1.25H20.5V7.16L22.87 9C23.0966 9.20657 23.233 9.49384 23.25 9.8V22.27C23.25 22.5352 23.1446 22.7896 22.9571 22.9771C22.7696 23.1646 22.5152 23.27 22.25 23.27L15.75 23.25Z"
        stroke={color}
        strokeRound
      />
    </BaseSvgStyle>
  );
}

export default House;
